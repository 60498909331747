import React from 'react'
import {AppBar, Toolbar, Typography,Box} from '@mui/material';
import { Link } from 'react-router-dom';
import './style.css';

const Navbar = () => {
  return (
    <>
   <AppBar position="static">
    <Toolbar>
    <Typography variant="h6" sx={{cursor:"pointer"}}>SPU</Typography>
    <Box sx={{display:'flex'}}>
      <Link to="/" className='link'>
        <Typography>Home</Typography>
    </Link>
    <Link to="/about" className='link'>
        <Typography>About</Typography>
    </Link>
    <Link to="/contact" className='link'>
        <Typography>Contact</Typography>
    </Link>
    </Box>
    </Toolbar>

    </AppBar>
    </>
  )
}

export default Navbar
