import React from 'react'
import img from './images/character_7.png';
const Home = () => {
  return (
    <div>
      <div className='master-header'>
        <div className='text'>Safe & reliable logistic solutions!</div>
        <div className='img'><img src={img} /></div>
      </div>
    </div>
  )
}

export default Home
